<template>
    <!--     content -->
    <div class="blog-detail-wrapper" v-if="complaint">
        <b-row>
            <!-- blogs -->
            <b-col cols="12">
                <b-card no-body class="overflow-hidden">
                    <swiper
                        class="swiper-navigations col-12"
                        :options="swiperOptions"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                        <swiper-slide :key="image.path" v-for="image in complaint.files">
                            <b-img
                                class="card-img-top"
                                :src="image.full_path"
                                fluid
                                v-if="image.media_type === 'image'"
                            />
                            <video controls class="card-img-top" v-if="image.media_type === 'video'">
                                <source :src="image.full_path" type="video/mp4">
                                {{ $t("Your browser does not support video.") }}
                            </video>
                            <audio controls class="card-img-top" v-if="image.media_type === 'audio'">
                                <source :src="image.full_path" type="audio/wav">
                                <source :src="image.full_path" type="audio/mp3">
                                {{ $t('Your browser does not support audio.') }}
                            </audio>
                        </swiper-slide>

                        <!-- Add Arrows -->
                        <div
                            slot="button-next"
                            class="swiper-button-next" v-show="complaint.files>1"
                        />
                        <div
                            slot="button-prev"
                            class="swiper-button-prev" v-show="complaint.files>1"
                        />
                        <div
                            slot="pagination"
                            class="swiper-pagination" v-show="complaint.files>1"
                        />
                    </swiper>
                    <b-card-body>
                        <b-card-title>
                            <div
                                class="blog-title-truncate text-body-heading">
                                <!--                                {{ complaint.ilce.name }}, {{ complaint.il.name }}-->
                                {{ complaint.title ? complaint.title : "Şikayet başlığı" }}
                            </div>
                        </b-card-title>
                        <b-media no-body>
                            <b-media-aside
                                vertical-align="center"
                                class="mr-50"
                            >
                                <b-avatar
                                    size="24"
                                    :src="complaint.user.profile_image"
                                />
                            </b-media-aside>
                            <b-media-body>
                                <small class="text-muted mr-50">by</small>
                                <small>
                                    <span class="text-body">
                                        {{ complaint.user.first_name }} {{ complaint.user.last_name }}
                                    </span>
                                    <!--                                    <b-link class="text-body">-->
                                    <!--                                        {{ complaint.user.first_name }} {{complaint.user.last_name }}-->
                                    <!--                                    </b-link>-->
                                </small>
                                <span class="text-muted ml-75 mr-50">|</span>
                                <small class="text-muted">{{ complaint.created_at }}</small>
                            </b-media-body>
                        </b-media>
                        <div class="my-1 py-25 d-flex justify-content-between">
                            <div>
                                <b-badge v-if="complaint.category"
                                         pill
                                         class="mr-75"
                                         variant="warning"
                                >
                                    {{ complaint.category.name }}
                                </b-badge>
                                <b-badge
                                    pill
                                    class="mr-75"
                                    :variant="statusColor(complaint.status)"
                                >
                                    {{ $t(complaint.status) }}
                                </b-badge>
                            </div>
                            <div class="d-flex align-items-end text-body cursor-pointer"
                            >
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          variant="warning" v-b-modal.modal-employee class="mr-2">
                                    <feather-icon
                                        icon="UserPlusIcon"
                                        class="mr-50"
                                    />
                                    <span class="text-capitalize">{{ $t('Assign employee') }}</span>
                                </b-button>

                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          variant="primary" class="" v-b-modal.modal-prevent-closing>
                                    <feather-icon
                                        icon="SettingsIcon"
                                        class="mr-50"
                                    />
                                    <span class="text-capitalize">{{ $t('Update Complaint Status') }}</span>
                                </b-button>
                            </div>
                        </div>
                        <div
                            class="blog-content"
                        >
                            {{ complaint.description }}
                        </div>
                        <hr class="my-2">
                        <div class="d-flex justify-content-between align-items-center">
                            <b-button
                                variant="info"
                                :href="'https://www.google.com/maps/search/?api=1&query='+complaint.lat+','+complaint.long"
                                target="_blank"
                            >
                                <div class="d-flex align-items-center text-body">
                                    <feather-icon
                                        icon="MapPinIcon"
                                        class="mr-25  text-white"
                                    />
                                    <span class="font-weight-bold  text-white">{{ $t('Location') }}</span>
                                </div>
                            </b-button>
                            <div>
                                <b-button variant="warning" :to="{ name: 'chat-employee-page', params: { id:  complaint.id} }"
                                          class="font-weight-bold mr-2 position-relative" v-if="this.complaint.calisan_id">

<!--                                    <notification-badge :messagesCount="this.complaint.message_not_road_count"/>-->
                                    <div class="d-flex align-items-center text-body">
                                        <feather-icon
                                            icon="UserIcon"
                                            class="mr-50 text-white"
                                        />
                                        <span class="font-weight-bold text-white">{{ $t('Chat with Assigned Employee') }}</span>
                                    </div>
                                </b-button>
                                <b-button variant="success" :to="{ name: 'chat-page', params: { id:  complaint.id} }"
                                          class="font-weight-bold position-relative">

                                    <notification-badge :messagesCount="this.complaint.message_not_road_count"/>
                                    <div class="d-flex align-items-center text-body">
                                        <feather-icon
                                            icon="SendIcon"
                                            class="mr-50 text-white"
                                        />
                                        <span class="font-weight-bold text-white">{{ $t('Go to chat') }}</span>
                                    </div>
                                </b-button>
                            </div>

                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <!--/ blogs -->

        </b-row>
        <!--/ blogs -->

        <!-- Complaint Status Modal -->
        <b-modal
            centered
            id="modal-prevent-closing"
            ref="my-modal"
            :title="$t('Update Complaint Status')"
            :ok-title="$t('submit')"
            :cancel-title="$t('cancel')"
            cancel-variant="outline-secondary"
            @show="showModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form
                ref="form"
                @submit.stop.prevent="handleSubmit"
                class="py-5"
            >
                <validation-observer ref="simpleRules">
                    <b-form-group
                        :state="true"
                        :label="$t('Complaint Status')"
                        label-for="name-input"
                        invalid-feedback="Name is required"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('Complaint Status')"
                        >
                            <v-select
                                v-model="complaintNewStatus"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="Complaint Status"
                                :options="complaintsStatusTexts"
                                :getOptionLabel="status => status.label"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </validation-observer>
            </form>
        </b-modal>

        <!-- Employee Modal -->
        <b-modal
            centered
            id="modal-employee"
            ref="modal-employee"
            :title="$t('Assign employee To Complaint')"
            :ok-title="$t('submit')"
            :cancel-title="$t('cancel')"
            cancel-variant="outline-secondary"
            @show="showModal"
            @hidden="resetModal"
            @ok="updateAssignedEmployee"
        >
            <form
                ref="form"
                @submit.stop.prevent="handleSubmit"
                class="py-5"
            >
                <validation-observer ref="simpleRules">
                    <b-form-group
                        :state="true"
                        :label="$t('Assign employee To Complaint')"
                        label-for="name-input"
                        invalid-feedback="Name is required"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('Complaint Status')"
                        >
                            <v-select
                                v-model="complaintAssignedEmployee"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="Assign employee To Complaint"
                                :options="employees"
                                :getOptionLabel="status => status.label"
                                :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </validation-observer>
            </form>
        </b-modal>
    </div>
    <!--/ content -->
</template>

<script>
import {
    BRow,
    BCol,
    BButton,
    BCard,
    BFormInput,
    BCardText,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BCardBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BPagination, BModal, VBModal,
} from 'bootstrap-vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {ValidationProvider, ValidationObserver, localize} from 'vee-validate'
import {
    required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import NotificationBadge from "@/views/pages/public/complaints/component/notificationBadge";

export default {
    name: "show",
    directives: {
        Ripple,
    },
    components: {
        NotificationBadge,
        BButton,
        BRow,
        BCol,
        BCard,
        BFormInput,
        BCardText,
        BCardBody,
        BCardTitle,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BLink,
        BBadge,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BImg,
        BPagination,
        Swiper,
        SwiperSlide,
        ContentWithSidebar,
        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            swiperOptions: {
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    // when window width is >= 640px
                    767: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    991: {
                        slidesPerView: 4,
                        spaceBetween: 40
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 40
                    }
                },
                centeredSlides: true,
                loop: false,
                initialSlide: 1,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
            complaintsStatus: [
                {title: 'submited', icon: 'FilePlusIcon'},
                {title: 'in_process', icon: 'ClockIcon'},
                {title: 'done', icon: 'CheckSquareIcon'},
                {title: 'canceled', icon: 'XCircleIcon'},
            ],
            complaintsStatusTexts: [
                {value: 'submited', label: this.$t('submited')},
                {value: 'in_process', label: this.$t('in_process')},
                {value: 'done', label: this.$t('done')},
                {value: 'canceled', label: this.$t('canceled')},
            ],
            complaintNewStatus: '',
            complaintAssignedEmployee: '',
        }
    },
    computed: {
        complaint() {
            return this.$store.state.complaints.singleComplaint;
        },
        employees() {
            let employeesSelect = [];
            for (let employeesKey in this.$store.state.complaints.employees) {
                employeesSelect.push({
                    value: this.$store.state.complaints.employees[employeesKey].id,
                    label: this.$store.state.complaints.employees[employeesKey].first_name + ' ' + this.$store.state.complaints.employees[employeesKey].last_name
                });
            }
            return employeesSelect;
        },
    },

    methods: {
        statusColor(status) {
            if (status === 'in_process') return 'light-info'
            if (status === 'canceled') return 'light-danger'
            if (status === 'submited') return 'light-primary'
            //if (status === 'Video') return 'light-warning'
            if (status === 'done') return 'light-success'
            return 'light-primary'
        },
        showModal() {
        },
        resetModal(bvModalEvt) {

            if (this.complaintNewStatus === "") {
                console.log(bvModalEvt);
                bvModalEvt.preventDefault();
            }
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('complaints/updateComplaintStatus', {
                        new_status: this.complaintNewStatus.value,
                        complaint_id: this.complaint.id
                    }).then(res => {
                        this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: this.$t('Successfully'),
                                    icon: 'InfoIcon',
                                    text: res.data.message,
                                    variant: 'success',
                                },
                            },
                            {
                                position: 'bottom-right',
                            })
                    })
                        .catch(err => {
                            this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: this.$t('Error'),
                                        icon: 'InfoIcon',
                                        text: err.response.data.message,
                                        variant: 'danger',
                                    },
                                },
                                {
                                    position: 'bottom-right',
                                })
                        })
                    // console.log("Send");

                    // Hide the modal manually
                    this.$nextTick(() => {
                        this.$refs['my-modal'].toggle('#toggle-btn')
                    })
                } else {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: this.$t('Please Check Entered Data'),
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                }
            })
        },
        updateSelectedEmployee() {
            if (this.complaint.calisan_id) {
                for (let employeesKey in this.employees) {
                    if (this.employees[employeesKey].value === this.complaint.calisan_id) {
                        this.complaintAssignedEmployee = {
                            value: this.complaint.calisan_id,
                            label: this.$store.state.complaints.employees[employeesKey].first_name + ' ' + this.$store.state.complaints.employees[employeesKey].last_name
                        };
                    }
                }
            }
        },
        updateAssignedEmployee() {
            this.$store.dispatch('complaints/updateAssignedEmployee', {
                calisan_id: this.complaintAssignedEmployee.value,
                complaint_id: this.complaint.id
            })
                .then((res => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Successfully'),
                                icon: 'InfoIcon',
                                text: res.data.message,
                                variant: 'success',
                            },
                        },
                        {
                            position: 'bottom-right',
                        });
                    this.updateSelectedEmployee();
                }))
                .catch((err => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.response.data.message,
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                }))
            console.log(this.complaintAssignedEmployee);
        },
    },
    created() {
        this.$store.dispatch('complaints/getSingleComplaint', this.$route.params.id)
            .then(res => {
                // console.log(res.data);
                this.complaintNewStatus = {label: this.$t(res.data.data.status), value: res.data.data.status};
                this.updateSelectedEmployee();
            })
            .catch(err => {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t('Error'),
                            icon: 'InfoIcon',
                            text: err.response.data.message,
                            variant: 'danger',
                        },
                    },
                    {
                        position: 'bottom-right',
                    })
            })
    },
}
</script>

<style scoped>
.cursor-pointer {

}

.card-img-top {
    height: 25em;
    object-fit: contain;
    object-position: center;
    border-radius: 12px;
    overflow: hidden;
}
</style>
