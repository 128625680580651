<template>
    <div class="my-badge bg-danger" v-if="messagesCount>0">
        {{messagesCount}}
    </div>
</template>

<script>
export default {
    name: "notificationBadge",
    props: {
        messagesCount: {
            type: Number,
            required: true
        }
    },
}
</script>

<style scoped>
.my-badge{
    position: absolute;
    pointer-events: none;
    top: -0.5em;
    right: -0.35em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
    min-width: 1.5em;
    min-height: 1.5em;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #000;
    padding: 0.65em;
    filter: drop-shadow(0 0 6px rgba(0,0,0,0.2));
}
</style>
